<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <div class="o-layout">
        <div class="o-layout__item">
          <div v-if="readonly" class="c-input--extra-info">
            This display template is read-only, because it is global. If you want to modify the template,
            create your own template and copy the contents of this template to start.
          </div>
        </div>
        <div class="o-layout__item">
          <div class="c-input c-input--text" :class="{'c-input--disabled': readonly, 'is-invalid': errors.has('name')}">
            <label class="c-input__label" for="name">{{$t('name')}}</label>
            <input v-model="name" class="c-input__field" name="name" type="text" id="name" v-validate
                   data-vv-rules="required|min:3" />
          </div>
        </div>
        <div class="o-layout__item">
          <div class="c-input c-input--text" :class="{'c-input--disabled': readonly, 'is-invalid': errors.has('description')}">
            <label class="c-input__label" for="description">{{$t('description')}}</label>
            <textarea v-model="description" class="c-input__field c-input__field-textarea"
                      name="description" type="text" rows="4" id="description" v-validate ></textarea>
          </div>
        </div>
        <div class="o-layout__item">
          <div class="c-input" :class="{'c-input--disabled': readonly, 'is-invalid': errors.has('parent')}">
            <label class="c-input__label" for="parent">{{$t('parent')}}</label>
            <select v-if="global" class="c-input__field c-input__field-select" v-model="parentSetId" name="parent" id="parent">
              <option v-for="parentTemplate in parentTemplates" :value="parentTemplate.id" :key="parentTemplate.id">
                {{parentTemplate.name}}
              </option>
            </select>
            <select v-else class="c-input__field c-input__field-select" v-model="parentSetId" name="parent" id="parent"
                    v-validate data-vv-rules="required">
              <option v-for="parentTemplate in parentTemplates" :value="parentTemplate.id" :key="parentTemplate.id">
                {{parentTemplate.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="o-layout__item">
          <div class="c-tabs c-main">
            <div class="c-tab__tab-bar">
              <button v-for="template in templates" :key="template.type"
                      class="c-button c-button--large c-button--ghost c-tabs__tab-link"
                      :class="{'is-active': template.type === currentTab}"
                      v-on:click.prevent="selectTemplate(template.type)">
                {{$t('displayType.' + template.type)}}
              </button><button key="css" :class="{'is-active': 'css' === currentTab}"
                      class="c-button c-button--large c-button--ghost c-tabs__tab-link"
                      v-on:click.prevent="selectCss()">
                css
              </button><button v-if="! readonly" v-on:click.prevent="selectType = true"
                  class="c-button c-button--large c-tabs__tab-action c-button--secondary">
              <svg role="img" class="c-button__icon">
                <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
              </svg>
              <span class="c-button__label">{{$t('layout.add')}}</span>
            </button>
            </div>
            <div class="c-tab__panel c-main u-margin-top-medium">
              <div class="o-layout">
                <div class="o-layout__item">
                  <button v-if="! readonly && currentTab !== 'css'" class="c-button c-button--large c-button--secondary"
                          v-on:click="removeType(currentTab)" style="float: right">
                    {{$t('delete')}}
                  </button>
                  <div class="c-input--extra-info">
                    {{$t('displayType.' + currentTab + '.description')}}
                  </div>
                </div>
                <div class="o-layout__item">
                  <prism-editor v-model="currentCode" :language="currentLanguage" :readonly="readonly"></prism-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <button v-if="! readonly && template" class="c-button c-button--secondary c-button--large" v-on:click.prevent="remove()">
          {{$t('delete')}}
        </button>
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.user.displayTemplates'}">
          <button class="c-button c-button--ghost c-button--large">{{readonly ? $t("close") : $t('cancel')}}</button>
        </router-link>
        <button v-if="! readonly" class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>
    <modal :shown="selectType" @closed="selectType = false">
      <div class="c-panel__dropdown">
        <h4 class="c-panel__label u-margin-bottom-small">
          {{$t('layout.add')}}
        </h4>
        <button v-for="type in typesUnused" :key="type"
                v-on:click.prevent="addType(type)"
                class="c-button c-button--small c-button--primary">
          {{$t('displayType.' + type)}}
        </button>
        <button v-on:click.prevent="selectType = false"
                class="c-button c-button--small c-button--secondary">
          {{$t('close')}}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import forEach from 'lodash/forEach';
  import map from 'lodash/map';

  import userLib from 'client/lib/user';

  import PrismEditor from 'vue-prism-editor';
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    name: 'admin-displayTemplates-set',
    components: {
      PrismEditor,
      'modal': require('client/components/modal.vue').default,
    },
    types: ['default', 'ranking', 'score', 'duel', 'presentation', 'announcement', 'block', 'medalWinner', 'medalWinners', 'flags'],
    props: ['template'],
    data: function() {
      return {
        readonly: true,
        name: null,
        description: null,
        parentSetId: null,
        global: false,
        templates: [],
        css: null,
        typesUnused: [],
        currentTab: 'css',
        currentCode: '',
        currentLanguage: 'html',
        selectType: false,
      };
    },
    computed: {
      parentTemplates: function () {
        let items = filter(this.$store.state.displayTemplates.items, item => {
          return ! this.template || this.template && item.id !== this.template.id;
        });
        let parents = [];
        let children = [];
        if (this.template) {
          children.push(this.template);
        }

        while (items.length) {
          items = filter(items, item => {
            if (! item.parentSetId) {
              parents.push(item);
              return false;
            }
            const parent = find(parents, parentItem => parentItem.id === item.parentSetId);
            if (parent) {
              parents.push(item);
              return false;
            }
            const child = find(children, childItem => childItem.id === item.parentSetId);
            if (child) {
              children.push(item);
              return false;
            }
            return true;
          });
        }
        return parents;
      },
    },
    created: function() {
      this.init();
    },
    methods: {
      init: function() {
        if (this.template) {
          this.name = this.template.name;
          this.description = this.template.description;
          this.parentSetId = this.template.parentSetId;
          this.global = this.template.global;
          this.css = this.template.css;
          let templates = [];
          let typesUnused = [];

          forEach(this.$options.types, type => {
            let template = find(this.template.templates, item => item.id === type);
            if (template) {
              templates.push({
                type: type,
                template: template.template,
              });
            } else {
              typesUnused.push(type);
            }
          });

          this.templates = templates;
          this.typesUnused = typesUnused;
        }

        this.readonly = this.global && ! userLib.checkRole('admin');

        this.currentTab = 'css';
        this.currentCode = this.css;
        this.currentLanguage = 'css';
      },
      copyEdits: function() {
        console.log('copy edits');
        if (this.currentTab === 'css') {
          console.log('copy css');
          this.css = this.currentCode;
        } else {
          console.log('copy', this.currentTab);
          const template = find(this.templates, item => item.type === this.currentTab);
          template.template = this.currentCode;
        }
      },
      selectTemplate: function(type) {
        if (this.currentTab === type) return;
        this.copyEdits();
        const template = find(this.templates, item => item.type === type);
        this.currentTab = type;
        this.currentCode = template.template;
        this.currentLanguage = 'html';
      },
      selectCss: function() {
        if (this.currentTab === 'css') return;
        this.copyEdits();
        this.currentTab = 'css';
        this.currentCode = this.css;
        this.currentLanguage = 'css';
      },
      addType: function(type) {
        this.selectType = false;
        this.templates.push({
          type: type,
          template: '',
        });
        this.typesUnused = filter(this.typesUnused, item => item !== type);
        this.selectTemplate(type);
      },
      removeType: function(type) {
        this.selectCss();
        this.templates = filter(this.templates, item => item.type !== type);
        this.typesUnused.push(type);
      },
      submit: function () {
        this.copyEdits();
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const templates = map(this.templates, item => {
              return {
                id: item.type,
                template: item.template,
              };
            });
            const id = this.template ? this.template.id : null;
            this.$emit('submit', {
              id: id,
              name: this.name,
              description: this.description,
              global: this.global,
              parentSetId: this.parentSetId,
              css: this.css,
              templates: templates,
            });
          } else {
            this.$notify.warning('Validation errors');
          }
        });
      },
      remove: function () {
        this.$emit('remove', this.template.id);
      }
    },
  };
</script>
